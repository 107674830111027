import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for validation
import Geforce1 from '../images/q1@2x.png'; // Import your images
import Geforce2 from '../images/q2@2x.png';
import Geforce3 from '../images/q3@2x.png';
import Geforce4 from '../images/q4@2x.png';
import Geforce5 from '../images/q5@2x.png';
import Geforce6 from '../images/q6@2x.png';
import Geforce7 from '../images/q7@2x.png';
import Geforce8 from '../images/q8@2x.png';
import Geforce9 from '../images/q9@2x.png';
import Geforce10 from '../images/q10@2x.png';
import Geforce11 from '../images/q11@2x.png';
import Geforce12 from '../images/q12@2x.png';

const OppositeDirectionNew = ({ index }) => {
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const imageRef = useRef(null);

    const images = [
        Geforce1, Geforce2, Geforce3, Geforce4, Geforce5,
        Geforce6, Geforce7, Geforce8, Geforce9, Geforce10,
        Geforce11, Geforce12
    ];

    // Ensure index is within the valid range
    const imageIndex = Number.isInteger(index) ? Math.max(0, Math.min(index - 1, images.length - 1)) : 0;

    console.log('Image Index:', imageIndex); // Debug statement

    const handleMouseMove = (e) => {
        if (imageRef.current) {
            const rect = imageRef.current.getBoundingClientRect();
            const imageCenter = {
                x: rect.left + rect.width / 2,
                y: rect.top + rect.height / 2,
            };

            const offset = {
                x: (e.clientX - imageCenter.x) * -0.1,
                y: (e.clientY - imageCenter.y) * -0.1,
            };

            setPosition({
                top: offset.y,
                left: offset.x,
            });
        }
    };

    const handleMouseLeave = () => {
        setPosition({ top: 0, left: 0 });
    };

    return (
        <div
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            className="flex justify-center items-center h-full relative w-full"
        >
            <img
                src={images[imageIndex]} // Use validated index
                alt={`${imageIndex + 1}`} // Adjust alt text as needed
                ref={imageRef}
                className="relative lg:absolute z-0"
                style={{
                    transition: 'transform 0.3s ease-out',
                    transform: `translate(${position.left}px, ${position.top}px)`,
                }}
            />
        </div>
    );
};

// Define prop types and default values
OppositeDirectionNew.propTypes = {
    index: PropTypes.number
};

OppositeDirectionNew.defaultProps = {
    index: 0
};

export default OppositeDirectionNew;
