import React, { useState, useEffect } from 'react';
import Client from '../images/client.svg';
import Nvidia from '../images/logo-nvidia.svg';
import Dell from '../images/DTTBP.svg';

const Navigation = ({ onStart }) => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`mx-auto left-0 right-0 p-10 sm:p-4 md:p-4 xl:p-10 justify-between flex flex-col md:flex-row content-center transition-all duration-300 z-50 bg-transparent p-10`}>
            <div
                className="Frame31 justify-center md:justify-start items-center gap-4 md:gap-5 w-full flex content-center">
                <div className="ClipPathGroup relative">
                    <div className="p-1">
                        <img src={Client} alt={'client'} className="w-full"/>
                    </div>
                </div>
                <div className="h-[30px] md:h-full w-[1px] md:w-[1px] opacity-40 bg-white">

                </div>

                <div className="LogoNvidia relative">
                    <div className="p-1">
                        <img src={Dell} alt={'Dell'}/>
                    </div>
                </div>
                <div className="h-[30px] md:h-full w-[1px] md:w-[1px] opacity-40 bg-white">

                </div>

                <div className="Dttbp relative">
                    <div className="p-1">
                        <img src={Nvidia} alt={'nvidia'}/>
                    </div>

                </div>
            </div>
            <div
                className=" text-center md:text-[12px] md:text-right w-full justify-center md:justify-end self-center xl:text-base text-base mt-[40px] md:mt-0 leading-tight">
                <div className="text-white  font-bold  uppercase leading-normal">The state of

                    <span className="text-amber-500 font-bold  uppercase leading-normal"> AI readiness </span>
                    <div className="md:hidden block"></div>
                    in the enterprise
                </div>
            </div>
        </div>
    );
};

export default Navigation;
