import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import OppositeDirectionImage from './components/OppositeDirection';
import LoadingThrobber from './components/LoadingThrobber';

const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const InitialForm = ({ onFormSubmit }) => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isFullNameValid, setIsFullNameValid] = useState(true);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true); // Add phone number validation state
    const [isCompanyNameValid, setIsCompanyNameValid] = useState(true); // Add company name validation state
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const storedFullName = localStorage.getItem('fullName');
        const storedEmail = localStorage.getItem('email');
        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        const storedCompanyName = localStorage.getItem('companyName');

        if (storedFullName) setFullName(storedFullName);
        if (storedEmail) setEmail(storedEmail);
        if (storedPhoneNumber) setPhoneNumber(storedPhoneNumber);
        if (storedCompanyName) setCompanyName(storedCompanyName);

        if (storedFullName) setIsFullNameValid(true);
        if (storedEmail && validateEmail(storedEmail)) setIsEmailValid(true);
    }, []);

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        localStorage.setItem('email', emailValue);
        setIsEmailValid(validateEmail(emailValue));
    };

    const handleFullNameChange = (e) => {
        const fullNameValue = e.target.value;
        setFullName(fullNameValue);
        localStorage.setItem('fullName', fullNameValue);
        setIsFullNameValid(fullNameValue.trim().length > 0);
    };

    const handlePhoneNumberChange = (e) => {
        const phoneNumberValue = e.target.value;
        setPhoneNumber(phoneNumberValue);
        localStorage.setItem('phoneNumber', phoneNumberValue);
    };

    const handleCompanyNameChange = (e) => {
        const companyNameValue = e.target.value;
        setCompanyName(companyNameValue);
        localStorage.setItem('companyName', companyNameValue);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setHasSubmitted(true);

        // Check for form validity
        let formIsValid = true;

        if (!fullName.trim()) {
            setIsFullNameValid(false);
            formIsValid = false;
        }

        if (!validateEmail(email)) {
            setIsEmailValid(false);
            formIsValid = false;
        }

        if (!formIsValid) {
            setErrorMessage('Please correct the highlighted errors before submitting.');
            return;
        }

        setIsLoading(true);

        const formId = 'ed745960-33f9-4a6c-8f11-3f815be36600';
        const portalId = '145352333';
        const accessToken = 'pat-eu1-e7d5da99-2db9-486b-8cee-8189c579c9e1';

        const submissionData = {
            fields: [
                { name: 'full_name', value: fullName },
                { name: 'email', value: email },
                { name: 'phone', value: phoneNumber },
                { name: 'company', value: companyName },
            ],
            context: {
                pageUri: window.location.href,
                pageName: 'Survey Initial Form',
            },
        };

        try {
            const response = await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, submissionData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            console.log('Form submitted successfully', response.data);
            setIsLoading(false);
            onFormSubmit({ fullName, email, phoneNumber, companyName });
        } catch (error) {
            console.error('Error submitting form', error);
            setErrorMessage('There was an error submitting the form. Please try again.');
            setIsLoading(false);
        }
    };

    const handleBackToForm = () => {
        navigate('/');
    };

    return (
        <div className="container mx-auto px-4 sm:px-10 flex flex-col-reverse lg:flex-row md:px-10 md:pt-[150px] lg:pt-[150px] md:pb-[100px] lg:p-6 lg:pb-[100px] xl:pt-[50px]">
            <div className="flex flex-col text-center md:text-left w-full lg:w-1/2 mb-8 md:mb-0">
                <div className="text-base leading-tight font-semibold mb-8 md:mb-[32px] text-white">
                    WELCOME TO THE <span className="text-amber-500">AI READINESS</span> SURVEY
                </div>
                <div className="w-24 h-1 opacity-90 bg-amber-500 mb-5 mx-auto md:ml-0 md:mb-[32px]" />
                <h1 className="text-[36px] md:text-5xl font-semibold mb-8 max-w-full md:max-w-[740px] leading-[150%] text-white">
                    Take 3 minutes to unlock your AI persona and be entered to win 1 of 3 Dell gaming computers* powered by NVIDIA
                </h1>
                {errorMessage && (
                    <p className="mb-8 text-red-500 font-bold">{errorMessage}</p>
                )}
                {isLoading ? (
                    <LoadingThrobber />
                ) : (
                    <form onSubmit={handleSubmit} className="flex flex-col space-y-6 mt-10">
                        <div className="flex flex-col md:flex-row gap-y-4 mb-4 md:gap-x-6">
                            <div
                                className={`relative transition-all duration-300 ease-in-out w-full md:w-64 md:mb-0 mb-[20px] ${
                                    !isFullNameValid && 'mb-[40px]'
                                }`}
                                htmlFor="fullName">
                                <label
                                    className={`absolute transition-all duration-300 ease-in-out ${
                                        fullName ? 'text-xs text-white -top-[25px] -left-[2px]' : 'top-2 left-2 text-lg text-white'
                                    }`}
                                    htmlFor="fullName"
                                >
                                    Full Name
                                </label>
                                <input
                                    id="fullName"
                                    type="text"
                                    className={`border rounded p-2 w-full bg-transparent placeholder-transparent text-white border-white appearance-none ${
                                        !isFullNameValid ? 'border-red-500' : ''
                                    }`}
                                    value={fullName}
                                    onChange={handleFullNameChange}
                                    onBlur={() => setIsFullNameValid(fullName.trim().length > 0)}
                                    required
                                />
                                {!isFullNameValid && (
                                    <p className="text-red-500 text-xs absolute mt-1">Full Name is required</p>
                                )}
                            </div>
                            <div className="relative w-full md:w-64">
                                <label
                                    className={`absolute transition-all duration-300 ease-in-out ${
                                        email ? 'text-xs text-white -top-[25px] -left-[2px]' : 'left-2 top-2 text-lg text-white'
                                    }`}
                                    htmlFor="email"
                                >
                                    Business Email
                                </label>
                                <input
                                    id="email"
                                    type="email"
                                    className={`border rounded p-2 w-full bg-transparent placeholder-transparent text-white border-white appearance-none ${
                                        !isEmailValid ? 'border-red-500' : ''
                                    }`}
                                    value={email}
                                    onChange={handleEmailChange}
                                    onBlur={() => setIsEmailValid(validateEmail(email))}
                                    required
                                />
                                {!isEmailValid && email.length > 0 && (
                                    <p className="text-red-500 absolute text-xs mt-1">Invalid email address</p>
                                )}
                            </div>
                        </div>

                        <div className="flex justify-center md:justify-start space-x-4 mt-10">
                            <button
                                type="submit"
                                id="initialformsubmit"
                                className="py-[12px] px-[24px] uppercase text-sm font-bold rounded bg-[#FF9E16] text-[#1e1f2b] hover:bg-[#0081BC] hover:text-white"
                            >
                                CONTINUE TO SURVEY
                            </button>
                        </div>
                        <div className="w-full mt-10">
                            <span className="text-white text-xs font-normal font-['Montserrat'] leading-none">
                                By clicking submit, you agree to receive other communications from Presidio. You can unsubscribe from these
                                communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are
                                committed to protecting and respecting your privacy, please review our <span
                                className="text-amber-500 text-xs font-normal font-['Montserrat'] underline leading-none">
                                    <a href="https://www.presidio.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                                </span>.
                                <br/><br/>
                                By clicking submit, you consent to allow Presidio to store and process the personal information submitted above to
                                provide you the content requested.
                                <br/><br/>
                                *Earn one entry to the raffle upon completion of a discovery call with Presidio. While supplies last.
                            </span>
                        </div>
                    </form>
                )}
            </div>
            <div className="w-full md:w-1/2 flex justify-center items-center mb-[60px] lg:mb-0">
                <OppositeDirectionImage/>
            </div>
        </div>
    );
};

export default InitialForm;
