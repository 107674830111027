import React, {useRef, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";
import Graphic from "./images/visionary_1@2x 1.png";
import Share from "./images/share.svg";

const Visionary = () => {
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const imageRef = useRef(null);

    const handleMouseMove = (e) => {
        const rect = imageRef.current.getBoundingClientRect();
        const imageCenter = {
            x: rect.left + rect.width / 2,
            y: rect.top + rect.height / 2,
        };

        const offset = {
            x: (e.clientX - imageCenter.x) * -0.1, // Opposite direction
            y: (e.clientY - imageCenter.y) * -0.1, // Opposite direction
        };

        setPosition({
            top: offset.y,
            left: offset.x,
        });
    };

    const handleMouseLeave = () => {
        setPosition({ top: 0, left: 0 });
    };
    const location = useLocation();
    const { fullName } = location.state || { fullName: 'Hey there' }; // default value if state is not provided

    return (
        <div
            className="flex container mx-auto flex-col-reverse lg:flex-row justify-center items-center content-center pt-[0px] md:pt-0 lg:h-screen p-4">
            <div className="w-full lg:w-1/2">
                <AnimatePresence mode='wait'>
                    <motion.div
                        initial={{opacity: 0, x: 100}}
                        animate={{opacity: 1, x: 0}}
                        exit={{opacity: 0, x: -100}}
                        transition={{duration: 0.3}}
                        className="my-8 w-full flex flex-col"
                    >
                        <div className="text-center w-full flex flex-col justify-center gap-4 mb-[32px]">
                            <div>
                                <span
                                    className="text-white text-base font-bold uppercase leading-normal">{fullName},</span>
                                <span
                                    className="text-white text-base font-bold uppercase leading-normal"> you are</span>
                            </div>
                            <div className="Rectangle12 w-24 mx-auto h-1 bg-amber-500"></div>
                        </div>
                        <div className="text-center text-white text-4xl md:text-5xl font-semibold uppercase mb-[58px]">
                            The Visionary
                        </div>
                        <div className="justify-center items-center flex mb-[42px]">
                            <div className="justify-center items-center gap-2 flex">
                                <div
                                    className="px-3 py-1 bg-[#FFFFFF40] rounded-3xl justify-center items-center gap-2.5 flex">
                                    <div className="text-white text-xs font-semibold leading-none">Creative</div>
                                </div>
                                <div
                                    className="px-3 py-1 bg-[#FFFFFF40] rounded-3xl justify-center items-center gap-2.5 flex">
                                    <div className="text-white text-xs font-semibold leading-none">Thoughtful</div>
                                </div>
                                <div
                                    className="px-3 py-1 bg-[#FFFFFF40] rounded-3xl justify-center items-center gap-2.5 flex">
                                    <div className="text-white text-xs font-semibold leading-none">Enthusiastic</div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mx-auto text-white text-xl font-normal mb-[32px]">
                            Visionaries recognize the incredible potential of AI and are thoughtfully considering how to
                            best integrate it into their operations for maximum impact.
                        </div>
                        <div className="Cta">
                            <div className="w-3.5 h-3.5 mx-auto bg-[#FF9E16] rounded-full mb-[20px]"/>
                            <div className="text-white text-base font-bold text-center leading-normal mb-[20px]">What
                                happens next:
                                <div className="font-normal mt-4 max-w-[500px] mb-8 mx-auto">
                                    A Presidio representative will contact you to schedule a discovery call. Earn one entry* to the prize draw upon completion of the call.
                                </div>
                            </div>
                            <div className="flex gap-5 justify-center ">

                                <div
                                    className="px-8 pt-2 pb-2 bg-amber-500 rounded justify-center items-center gap-2.5 flex">
                                    <a href="https://www.presidio.com/solutions/ai/" target="_blank" rel="noreferrer"
                                       className="text-zinc-800 text-sm font-bold uppercase leading-tight">View Presidio
                                        AI solutions
                                    </a>
                                </div>
                                <div
                                    className="px-8 hidden pt-2 pb-2 bg-[#FFFFFF40] rounded justify-center items-center gap-2.5 flex">
                                    <div
                                        className="text-white text-sm font-bold uppercase leading-0 flex gap-2 align-middle content-center items-center">Share <img
                                        src={Share} alt={'Share'}/></div>
                                </div>

                            </div>
                            <div
                                className="WhileSuppliesLast text-center text-white text-[9px] font-normal leading-[13.50px] mt-[30px]">
                                *Winning entries will be confirmed by email with instructions on how to claim prize. While supplies last.
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
            <div className="w-full lg:w-1/2 flex items-center justify-center">
                <AnimatePresence mode='wait'>
                    <motion.div
                        initial={{opacity: 0, x: -100}}
                        animate={{opacity: 1, x: 0}}
                        exit={{opacity: 0, x: 100}}
                        transition={{duration: 0.3}}
                        className="my-8 w-full flex flex-col"
                    >
                        <div
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave}
                            className={'flex justify-center items-center h-full lg:h-screen relative w-full'}
                        >
                            <img
                                src={Graphic}
                                alt="GeForce"
                                ref={imageRef}
                                className={'relative lg:absolute'}
                                style={{
                                    transition: 'transform 0.3s ease-out', // Increase duration for a smoother reset
                                    transform: `translate(${position.left}px, ${position.top}px)`,
                                }}
                            />
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
};

export default Visionary;
