// src/components/LoadingThrobber.jsx
import React, { useState, useEffect } from 'react';
import Spinner from "../images/throbber.gif";

const LoadingThrobber = ({ duration = 3000 }) => { // Accept duration as a prop
    const [loadingPercentage, setLoadingPercentage] = useState(0);

    useEffect(() => {
        const incrementInterval = 30; // Update interval in milliseconds
        const incrementValue = 100 / (duration / incrementInterval); // Increment value to reach 100% in duration

        const interval = setInterval(() => {
            setLoadingPercentage((prev) => {
                if (prev < 100) {
                    return Math.min(prev + incrementValue, 100); // Ensure it doesn't exceed 100%
                }
                clearInterval(interval);
                return 100;
            });
        }, incrementInterval);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, [duration]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingPercentage(100); // Ensure 100% when duration ends
        }, duration);

        // Cleanup timer on component unmount
        return () => clearTimeout(timer);
    }, [duration]);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="Group3453 w-28 h-28 justify-center items-center inline-flex">
                <div className="Group3451 w-28 h-28 relative">
                    <img src={Spinner} alt="Throbber" />
                </div>
            </div>
            <div className="absolute bottom-10 text-white text-xl">{loadingPercentage.toFixed(0)}%</div>
        </div>
    );
};

export default LoadingThrobber;
