import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate, useLocation } from 'react-router-dom';
import Survey from './Survey';
import Introduction from './Introduction';
import IntroductionNew from './IntroductionNew';
import InitialForm from './InitialForm';
import Explorer from './Explorer';
import Visionary from './Visionary';
import Architect from './Architect';
import Champion from './Champion';
import './index.css';
import Navigation from "./components/Navigation";

const App = () => {
    return (
        <Router>
            <div
                className="App font-sans w-screen min-h-fit h-screen bg-black bg-gradient-to-b from-[#1E1F2B80] from-20% to-[#0081BC] scroll-auto">
                <div className={'h-full md:h-screen w-screen fixed z-0 bg-black  bg-gradient-to-b from-[#1E1F2B80] from-20% to-[#0081BC]'}></div>
                <div className={'texture h-full md:h-screen w-screen fixed z-0 opacity-10 '}/>

                <div className={'relative'}>
                    <Navigation/>
                    <Routes>
                        <Route path="/" element={<IntroductionWrapper/>}/>
                        <Route path="/get-ready" element={<IntroductionNewWrapper/>}/>
                        <Route path="/form" element={<InitialFormWrapper/>}/>
                        <Route path="/survey" element={<SurveyWrapper/>}/>
                        <Route path="/explorer" element={<ExplorerWrapper/>}/>
                        <Route path="/visionary" element={<VisionaryWrapper/>}/>
                        <Route path="/architect" element={<ArchitectWrapper/>}/>
                        <Route path="/champion" element={<ChampionWrapper/>}/>
                    </Routes>
                </div>
            </div>
        </Router>
    );
};

// Custom hook to update page title
const usePageTitle = (title) => {
    useEffect(() => {
        document.title = title;
    }, [title]);
};

const IntroductionWrapper = () => {
    const navigate = useNavigate();
    usePageTitle('Introduction - AI Readiness Survey'); // Set dynamic page title

    const startSurvey = () => {
        navigate('/form');
    };

    return <Introduction onStart={startSurvey} />;
};

const IntroductionNewWrapper = () => {
    usePageTitle('Introduction - AI Readiness Survey'); // Set dynamic page title
    return <IntroductionNew />;
};

const InitialFormWrapper = () => {
    const navigate = useNavigate();
    usePageTitle('Initial Form - AI Readiness Survey'); // Set dynamic page title

    const handleFormSubmit = (userData) => {
        navigate('/survey', { state: { user: userData } });
    };

    return <InitialForm onFormSubmit={handleFormSubmit} />;
};

const SurveyWrapper = () => {
    const location = useLocation();
    const { user } = location.state || {};

    // A list of titles for each question
    const questionTitles = [
        "Question 1 - Do you have an AI strategy? - AI Readiness Survey",
        "Question 2 - AI Expertise - AI Readiness Survey",
        "Question 3 - Data Infrastructure - AI Readiness Survey",
        "Question 4 - Data Quality and Quantity - AI Readiness Survey",
        "Question 5 - Data Storage - AI Readiness Survey",
        "Question 6 - AI Applications - AI Readiness Survey",
        "Question 7 - GPU Usage - AI Readiness Survey",
        "Question 8 - AI Goals - AI Readiness Survey",
        "Question 9 - AI Challenges - AI Readiness Survey",
        "Question 10 - AI Budget Allocation - AI Readiness Survey",
        "Question 11 - AI Investment Plans - AI Readiness Survey",
        "Question 12 - GPU Applications - AI Readiness Survey"
    ];

    const [currentQuestion, setCurrentQuestion] = React.useState(0); // Track current question

    // Update the page title when the current question changes
    useEffect(() => {
        if (currentQuestion < questionTitles.length) {
            document.title = questionTitles[currentQuestion];
        }
    }, [currentQuestion]); // Update when current question changes

    return user ? (
        <Survey user={user} currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} />
    ) : (
        <Navigate to="/" />
    );
};


const ExplorerWrapper = () => {
    usePageTitle('Explorer - AI Readiness Survey'); // Set dynamic page title
    return <Explorer />;
};

const VisionaryWrapper = () => {
    usePageTitle('Visionary - AI Readiness Survey'); // Set dynamic page title
    return <Visionary />;
};

const ArchitectWrapper = () => {
    usePageTitle('Architect - AI Readiness Survey'); // Set dynamic page title
    return <Architect />;
};

const ChampionWrapper = () => {
    usePageTitle('Champion - AI Readiness Survey'); // Set dynamic page title
    return <Champion />;
};

export default App;
