import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import OppositeDirectionImage from './components/OppositeDirectionNew';
import './index.css';

const Survey = ({ user }) => {
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [customValues, setCustomValues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [score, setScore] = useState(null); // Set initial score to null to signify it's not yet calculated
    const [outcome, setOutcome] = useState(''); // Outcome state
    const [rangeValue, setRangeValue] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission status
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/questions.json')
            .then(response => response.json())
            .then(data => {
                const requiredQuestions = data.questions.map(question => ({
                    ...question,
                    required: true,
                }));
                setQuestions(requiredQuestions);
                setAnswers(Array(requiredQuestions.length).fill([]));
                setCustomValues(Array(requiredQuestions.length).fill(''));
                setLoading(false);
            })
            .catch(error => {
                console.error('Error loading questions:', error);
                setLoading(false);
            });
    }, []);

    // Calculate score and outcome when the form is about to be submitted
    const calculateScoreAndOutcome = () => {
        let totalScore = 0;

        answers.forEach((answer, index) => {
            const question = questions[index];
            answer.forEach(choice => {
                const value = question.values ? question.values[choice] : 0;
                if (value !== undefined) {
                    totalScore += value;
                }
            });
        });

        setScore(totalScore);

        // Set outcome based on score
        let surveyOutcome = '';
        if (totalScore >= 0 && totalScore <= 20) {
            surveyOutcome = 'Explorer';
        } else if (totalScore > 20 && totalScore <= 40) {
            surveyOutcome = 'Visionary';
        } else if (totalScore > 40 && totalScore <= 60) {
            surveyOutcome = 'Architect';
        } else if (totalScore > 60) {
            surveyOutcome = 'Champion';
        }

        setOutcome(surveyOutcome);
    };

    // Monitor score and outcome before submitting form
    useEffect(() => {
        if (formSubmitted && score !== null) {
            handleSubmitForm(); // Only submit form once the score is calculated
        }
    }, [score, outcome]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleNext = () => {
        const currentQuestionData = questions[currentQuestion];

        // Validate range input specifically
        if (currentQuestionData.type === 'range' && rangeValue === null) {
            setErrorMessage('This question is required. Please provide a rating.');
            return;
        }

        // General validation for required questions
        if (currentQuestionData.required && (answers[currentQuestion].length === 0 && (currentQuestionData.type !== 'range' || rangeValue === null))) {
            setErrorMessage('This question is required. Please provide an answer.');
            return;
        }

        setErrorMessage('');
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
            scrollToTop();
        }
    };

    const handleBack = () => {
        setErrorMessage('');
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
            scrollToTop();
        }
    };

    const handleCheckboxChange = (e, choice) => {
        const updatedAnswers = [...answers];
        if (e.target.checked) {
            updatedAnswers[currentQuestion] = [...updatedAnswers[currentQuestion], choice];
        } else {
            updatedAnswers[currentQuestion] = updatedAnswers[currentQuestion].filter(
                (item) => item !== choice
            );
            if (choice === "Other – please specify...") {
                setCustomValues(customValues.map((value, index) => index === currentQuestion ? '' : value));
            }
        }
        setAnswers(updatedAnswers);
    };

    const handleRadioChange = (choice) => {
        const updatedAnswers = [...answers];
        updatedAnswers[currentQuestion] = [choice];
        setAnswers(updatedAnswers);
    };

    const handleRangeChange = (e) => {
        const newValue = e.target.value;
        setRangeValue(newValue);
        const updatedAnswers = [...answers];
        updatedAnswers[currentQuestion] = [newValue];
        setAnswers(updatedAnswers);
    };

    const handleCustomValueChange = (e) => {
        const updatedCustomValues = [...customValues];
        updatedCustomValues[currentQuestion] = e.target.value;
        setCustomValues(updatedCustomValues);
    };

    const handleFormSubmit = () => {
        // Trigger score and outcome calculation before submitting form
        calculateScoreAndOutcome();
        setFormSubmitted(true); // Track the form submission
    };


    const handleSubmitForm = () => {
        const hutk = getCookie('hubspotutk');  // Get the HubSpot cookie
        const formId = 'ed745960-33f9-4a6c-8f11-3f815be36600';
        const portalId = '145352333';

        const fields = [
            { name: 'full_name', value: user.fullName },
            { name: 'email', value: user.email },
            { name: 'survey_outcome2', value: score?.toString() }, // Convert score to string
            { name: 'survey_persona', value: outcome }, // Pass the calculated outcome
            { name: 'how_would_you_rate_the_quality_and_quantity_of_the_data_available_for_ai_projects_in_your_organiza', value: rangeValue?.toString() || '' }, // Range value
            ...answers.map((answer, index) => {
                const isOtherSelected = answer.includes("Other – please specify...");
                const otherFieldValue = customValues[index];

                // Prepare data to submit to HubSpot, including the "Other" input
                return [
                    {
                        name: questions[index].field_name,
                        value: answer.filter(choice => choice !== "Other – please specify...").join(', ') // Normal answers
                    },
                    // Map "Other" fields to the correct HubSpot contact property name if "Other" is selected and a custom value is provided
                    isOtherSelected && otherFieldValue && index === 7
                        ? { name: 'other_what_are_the_primary_goals_of_your_ai_initiatives_', value: otherFieldValue }
                        : null,
                    isOtherSelected && otherFieldValue && index === 8
                        ? { name: 'other_what_are_the_biggest_challenges_you_face_in_implementing_ai_solutions', value: otherFieldValue }
                        : null,
                    isOtherSelected && otherFieldValue && index === 11
                        ? { name: 'other_which_applications_are_you_using_gpus_for', value: otherFieldValue }
                        : null
                ].filter(Boolean); // Filter out null entries
            }).flat() // Flatten the array
        ];

        fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fields: fields,
                context: {
                    hutk: hutk,
                    pageUri: window.location.href,
                    pageName: 'Survey'
                }
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log('Form submitted successfully', data);
                scrollToTop();
                // Redirect based on outcome
                if (outcome === 'Explorer') {
                    navigate('/explorer', { state: { fullName: user.fullName } });
                } else if (outcome === 'Visionary') {
                    navigate('/visionary', { state: { fullName: user.fullName } });
                } else if (outcome === 'Architect') {
                    navigate('/architect', { state: { fullName: user.fullName } });
                } else if (outcome === 'Champion') {
                    navigate('/champion', { state: { fullName: user.fullName } });
                }
            })
            .catch(error => {
                console.error('Error submitting form', error);
            });
    };

    // Helper function to retrieve the 'hutk' cookie
    const getCookie = (cookieName) => {
        const name = cookieName + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i].trim();
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    const question = questions[currentQuestion];
    const imageIndex = question?.image_index || 0; // Default to 0 if undefined

    const renderRangeValues = (min, max) => {
        const values = [];
        for (let i = min; i <= max; i++) {
            values.push(i);
        }
        return values;
    };

    return (
        <div className="flex container mx-auto flex-col-reverse md:flex-row justify-center items-center content-center md:pt-[150px] md:h-full p-4 lg:pt-[100px] xl:pt-[150px] xl:pb-[150px] pb-[100px]">
            <div className="w-full bg-[#FFFFFF40] h-1 fixed top-0 left-0 z-50">
                <div
                    className="bg-blue-600 h-full transition-width duration-500"
                    style={{ width: `${((currentQuestion + 1) / questions.length) * 100}%` }}
                ></div>
            </div>
            <div className="w-full md:w-1/2 text-center md:text-left">
                <AnimatePresence mode='wait'>
                    <motion.div
                        key={currentQuestion}
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -100 }}
                        transition={{ duration: 0.3 }}
                        className="my-8 w-full"
                    >
                        <div className="text-base leading-tight font-semibold mb-[32px] text-white">
                            {`QUESTION ${currentQuestion + 1}/${questions.length}`}
                        </div>
                        <div className="hidden md:flex Rectangle6 w-24 h-1 opacity-90 bg-amber-500 mb-[20px]" />
                        <h2 className="xl:text-4xl md:leading-[150%] md:text-3xl font-semibold mb-[32px] text-white text-[28px]">
                            {question.text}
                        </h2>
                        <div className="text-base leading-tight font-semibold mb-[32px] text-white">
                            {question.type === 'multiple' ? 'Select all that apply:' : question.type === 'single' ? 'Select one:' : 'Rate your satisfaction:'}
                        </div>
                        <div className="flex flex-col justify-center md:justify-start md:flex-row flex-wrap gap-[20px] mb-[20px]">
                            {question.type === 'range' ? (
                                <div className="w-full">
                                    <div className="flex justify-between mb-2 text-[#ffffff60] font-semibold">
                                        {renderRangeValues(question.min, question.max).map(value => (
                                            <span
                                                key={value}
                                                id={value}
                                                className={`w-10 text-center text-2xl cursor-pointer ${rangeValue == value ? 'font-semibold text-white' : ''}`}
                                                onClick={() => setRangeValue(value)}
                                            >
                                                {value}
                                            </span>
                                        ))}
                                    </div>
                                    <div className="flex justify-between mt-2 mb-5 text-white text-sm opacity-60">
                                        <span>Poor</span>
                                        <span>Great</span>
                                    </div>
                                    <input
                                        type="range"
                                        min={question.min}
                                        max={question.max}
                                        value={rangeValue || (question.min + question.max) / 2}
                                        onChange={handleRangeChange}
                                        className="w-full appearance-none bg-[#FFFFFF40] h-2 rounded-full slider-thumb"
                                    />
                                    <div className="text-white text-center mt-2 hidden">
                                        {rangeValue || (question.min + question.max) / 2}
                                    </div>
                                </div>
                            ) : question.choices.map((choice, index) => (
                                <div key={index} className="relative flex w-full md:w-auto">
                                    <label className="flex items-center w-full md:w-auto">
                                        {question.type === 'multiple' ? (
                                            <input
                                                type="checkbox"
                                                id={answers[currentQuestion].includes(choice)}
                                                checked={answers[currentQuestion].includes(choice)}
                                                onChange={(e) => handleCheckboxChange(e, choice)}
                                                className="hidden"
                                            />
                                        ) : (
                                            <input
                                                type="radio"
                                                id={answers[currentQuestion][0] === choice}
                                                checked={answers[currentQuestion][0] === choice}
                                                onChange={() => handleRadioChange(choice)}
                                                className="hidden"
                                            />
                                        )}
                                        <motion.div
                                            id={choice.replace(/\s+/g, '-')}
                                            className={`border w-full border-white rounded-full px-8 py-2 cursor-pointer transition-all duration-300 ${
                                                question.type === 'multiple' && answers[currentQuestion].includes(choice)
                                                    ? 'bg-[#0081BC] !border-[#0081BC] text-white'
                                                    : 'text-white'
                                            } ${
                                                question.type === 'single' && answers[currentQuestion][0] === choice
                                                    ? 'bg-[#0081BC] !border-[#0081BC] text-white'
                                                    : 'text-white'
                                            }`}
                                        >
                                            {choice}
                                        </motion.div>
                                    </label>
                                    <AnimatePresence>
                                        {choice === "Other – please specify..." && answers[currentQuestion].includes(choice) && (
                                            <motion.input
                                                initial={{ width: 0, opacity: 0 }}
                                                animate={{ width: 'auto', opacity: 1 }}
                                                exit={{ width: 0, opacity: 0 }}
                                                transition={{ duration: 0.3 }}
                                                type="text"
                                                value={customValues[currentQuestion]}
                                                onChange={handleCustomValueChange}
                                                placeholder="Please specify..."
                                                className="ml-4 border border-white rounded-full px-8 py-2 cursor-pointer transition-all bg-transparent duration-300 text-white"
                                            />
                                        )}
                                    </AnimatePresence>
                                </div>
                            ))}
                        </div>
                    </motion.div>
                </AnimatePresence>
                {errorMessage && <div className="text-[#FF9E16] mb-4">{errorMessage}</div>}
                <div className="flex space-x-4 justify-center md:justify-start mt-[80px]">
                    {currentQuestion === 0 ? (
                        <button
                            onClick={() => navigate('/form')}
                            className="bg-[#FFFFFF20] text-white py-2 px-6 text-sm font-bold rounded"
                        >
                            BACK
                        </button>
                    ) : (
                        <button
                            onClick={handleBack}
                            className="bg-[#FFFFFF20] text-white py-2 text-sm font-bold px-6 rounded hover:bg-[#5F6062] hover:text-white"
                        >
                            BACK
                        </button>
                    )}
                    {currentQuestion < questions.length - 1 ? (
                        <button
                            onClick={handleNext}
                            id={questions[currentQuestion].field_name}
                            className="bg-[#FF9E16] text-[#1e1f2b] py-2 px-6 text-sm font-bold rounded hover:bg-[#0081BC] hover:text-white"
                        >
                            NEXT
                        </button>
                    ) : (
                        <button
                            onClick={handleFormSubmit}
                            id="SurveySubmit"
                            className="bg-[#FF9E16] text-sm font-bold text-[#1e1f2b] py-2 px-4 rounded hover:bg-[#0081BC] hover:text-white"
                        >
                            SUBMIT
                        </button>
                    )}
                </div>
            </div>
            <div className="w-full md:w-1/2 mt-[0px] mb-[50px] md:mb-0 flex items-center justify-center">
                <AnimatePresence mode='wait'>
                    <motion.div
                        key={currentQuestion}
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        transition={{ duration: 0.3 }}
                        className="my-8 w-full"
                    >
                        <OppositeDirectionImage index={imageIndex} />
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
};

export default Survey;
