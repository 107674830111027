import React, { useState, useRef } from 'react';
import Geforce from '../images/dell-gaming-pc-nvidia.png'; // Import your image

const OppositeDirection = () => {
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const imageRef = useRef(null);

    const handleMouseMove = (e) => {
        const rect = imageRef.current.getBoundingClientRect();
        const imageCenter = {
            x: rect.left + rect.width / 2,
            y: rect.top + rect.height / 2,
        };

        const offset = {
            x: (e.clientX - imageCenter.x) * -0.1, // Opposite direction
            y: (e.clientY - imageCenter.y) * -0.1, // Opposite direction
        };

        setPosition({
            top: offset.y,
            left: offset.x,
        });
    };

    const handleMouseLeave = () => {
        setPosition({ top: 0, left: 0 });
    };

    return (
        <div
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            className={'flex justify-center items-center h-full relative w-full'}
        >
            <img
                src={Geforce}
                alt="GeForce"
                ref={imageRef}
                className={'relative lg:absolute max-h-[600px]'}
                style={{
                    transition: 'transform 0.3s ease-out', // Increase duration for a smoother reset
                    transform: `translate(${position.left}px, ${position.top}px)`,
                }}
            />
        </div>
    );
};

export default OppositeDirection;
