// src/Introduction.js

import React from 'react';

const Introduction = ({ onStart }) => {
    return (
        <div className="flex flex-col items-center justify-center h-screen text-center fixed w-full top-0">
            <div className={'w-24 h-1 bg-[#FF9E16] mx-auto mb-10'}/>
            <h1 className="text-[42px] md:text-7xl font-semibold mb-8 text-white uppercase">Are you Ai Ready?</h1>
            <p className="mb-8 text-white">
                Benchmark yourself against the current status of AI integration and adoption in the modern enterprise
            </p>
            <button
                onClick={onStart}
                className="bg-[#FF9E16] text-[#333333] py-3 px-6 rounded font-bold text-sm hover:bg-[#0081BC] hover:text-white"
            >
                TAKE THE SURVEY
            </button>
        </div>
    );
};

export default Introduction;
